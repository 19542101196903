import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import AccessibilityLink from "../AccessibilityLink";
import {
    showAccessibilityLink,
    accessiblityLinkSites,
} from "../../../../lib/helpers";
import OneTrustScript from "../../../OneTrustScript";
import Footer from "../../../Layout/components/Footer";

import styles from "./styles.module.less";

const ucLoader =
    typeof window !== "undefined" ? require("../../../../lib/ucLoader") : null;

function Layout({
    children,
    header = null,
    headBeginScript = [],
    headEndScript = [],
    bodyBeginScript = [],
    bodyEndScript = [],
    staticTextItems,
}) {
    const renderHeadScripts = scripts => {
        return (
            <Helmet>
                {scripts
                    .map((el, i) => {
                        const { name, html, async, src } = el;
                        if (name === "script") {
                            if (src) {
                                return (
                                    <script key={i} src={src} async={async} />
                                );
                            } else {
                                return (
                                    <script key={i} async={async}>
                                        {html}
                                    </script>
                                );
                            }
                        }
                        if (name === "noscript") {
                            return <noscript key={i}>{html}</noscript>;
                        }
                        return null;
                    })
                    .filter(el => !!el)}
                <script
                    dangerouslySetInnerHTML={{
                        __html: ucLoader,
                    }}
                />
            </Helmet>
        );
    };

    const renderBodyScripts = scripts => {
        return (
            <>
                {scripts
                    .map((el, i) => {
                        const { name, html, ...rest } = el;
                        if (el.name === "script") {
                            return (
                                <script
                                    key={i}
                                    {...rest}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            );
                        }
                        if (el.name === "noscript") {
                            return (
                                <noscript
                                    key={i}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            );
                        }
                        return null;
                    })
                    .filter(el => !!el)}
            </>
        );
    };

    const showLink = showAccessibilityLink(accessiblityLinkSites.G2H);
    return (
        <>
            <OneTrustScript />
            {renderHeadScripts(headBeginScript)}
            {renderBodyScripts(bodyBeginScript)}
            <Helmet bodyAttributes={{ class: styles.mktBody }}></Helmet>
            {showLink && <AccessibilityLink />}
            {header}
            <div>{children}</div>
            <Footer staticTextItems={staticTextItems} />
            {renderBodyScripts(bodyEndScript)}
            {renderHeadScripts(headEndScript)}
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
