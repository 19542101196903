import React from "react";
import Helmet from "react-helmet";

import Layout from "../../../components/GivingToHeroesDetail/components/Layout";
import Header from "../../../components/GivingToHeroesDetail/components/Header";

import styles from "./styles.module.less";

const GivingToHeroesAccessibility = () => {
    const header = <Header active={[]} showMenu={false} />;

    return (
        <Layout header={header}>
            <Helmet>
                <title>
                    Accessibility Statement | Bay Equity - Giving To Heroes
                </title>
                <meta name="description" content="Accessibility Statement" />
                <meta name="keywords" content="accessibility statement" />
                <script
                    type="text/javascript"
                    src="https://bayequity.usablenet.com/pt/accessibilitystatement.js?l=1"
                    async></script>
                <meta name="viewport" content="width=device-width" />
                <html lang="en" />
            </Helmet>
            <div
                className={styles.accessibility}
                id="a40AccessibilityStatement"></div>
        </Layout>
    );
};

export default GivingToHeroesAccessibility;
