import React from "react";
import classnames from "classnames";

import Accent from "../../../Accent";

import styles from "./styles.module.less";
import heroes from "../../../Hero/heroes.module.less";

const Header = ({
    hero = {
        heroBackground: "americanFlag",
        customPhoto: "",
        photoAlignment: "center",
        title: "Save Thousands of Dollars on Your Next Real Estate Transaction",
        html: "We're here to get you home.",
        darkText: false,
    },
    showLinkAboutTheProgram = true,
    showLinkPotentialSavings = true,
    showLinkAboutBayEquity = true,
    showLinkTestimonials = true,
}) => {
    let cls = classnames(styles.baseStyles, {
        [heroes[hero.heroBackground]]: !hero.customPhoto,
    });
    let style = {
        backgroundPosition: hero.photoAlignment || "center",
    };
    if (!!hero.customPhoto) {
        style.backgroundImage = `url("${hero.customPhoto}")`;
    }
    return (
        <div className={cls} style={style}>
            <div className={styles.heroCopy}>
                <nav className={styles.nav}>
                    <div className={styles.logo}></div>
                    <div className={styles.navZoom}>
                        <ul className={styles.links}>
                            {showLinkAboutTheProgram && (
                                <li
                                    key="about-the-program"
                                    className={styles.link}>
                                    <a href={`#about-the-program`}>
                                        About the Program
                                    </a>
                                </li>
                            )}
                            {showLinkPotentialSavings && (
                                <li
                                    key="potential-savings"
                                    className={styles.link}>
                                    <a href={`#potential-savings`}>
                                        Potential Savings
                                    </a>
                                </li>
                            )}
                            {showLinkAboutBayEquity && (
                                <li
                                    key="about-bay-equity"
                                    className={styles.link}>
                                    <a href={`#about-bay-equity`}>
                                        About Bay Equity
                                    </a>
                                </li>
                            )}
                            {showLinkTestimonials && (
                                <li key="testimonials" className={styles.link}>
                                    <a href={`#testimonials`}>Testimonials</a>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={styles.contactBtn}>
                        <a href="#potential-savings">Contact Us</a>
                    </div>
                </nav>
                <div
                    className={`${styles.heroHeader} container-fluid ${styles.top}`}>
                    <div
                        className={styles.heroText}
                        style={hero.darkText ? { color: "black" } : {}}>
                        <div>
                            <h1>{hero.title}</h1>
                        </div>
                        <Accent align="left"></Accent>
                        <div>{hero.html}</div>
                    </div>
                    <div className={styles.heroIcon}>
                        <img
                            className={`logoimg`}
                            alt="Giving To Heroes"
                            src="https://baycdn.blob.core.windows.net/homeloans/site-assets/giving-to-heroes/giving-to-heroes-logo-solid.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
